const routes = [
  {
    path: 'core/member',
    name: 'CoreMember',
    component: () => import('pages/core/Member.vue'),
  },
  {
    path: 'core/branch',
    name: 'CoreBranch',
    component: () => import('pages/core/Branch.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: 'core/department',
    name: 'CoreDepartment',
    component: () => import('pages/core/Department.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: 'core/notification',
    name: 'CoreNotification',
    component: () => import('pages/core/NotificationPage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
];

export default routes;
