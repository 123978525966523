// This is just an example,
// so you can safely delete all default props below

export default {
  failed: 'Action failed',
  success: 'Action was successful',
  m: {
    errorCode: {
      500: '內部伺服器錯誤',
      100008: '權限不足',
    },
    common: {
      add: 'Add',
      delete: 'Delete',
      update: 'Update',
      publish: 'Publish',
      hide: 'Hide',
    },
    login: {
      dialog: {
        noAuth: {
          message: '權限不足',
        },
        invalidPassword: {
          message: 'Invalid Password',
        },
      },
    },
    banner: {
      menu: {
        title: 'Banner',
      },
    },
    bulletin: {
      menu: {
        title: 'Bulletin',
      },
    },
    category: {
      menu: {
        title: 'Category',
      },
      table: {
        title: 'Category Management',
        cover: 'Cover',
        parentCategory: 'Parent Category',
        categoryName: 'Category Name',
        description: 'Description',
        dateAdd: 'Date Add',
        active: 'Active',
      },
    },
    product: {
      menu: {
        title: 'Product',
      },
      table: {
        title: 'Product Management',
        cover: 'Cover',
        productName: 'Product Name',
        description: 'Description',
        price: 'Price',
        dateAdd: 'Date Add',
      },
      dialog: {
        productAttr: 'Product Attrubite',
        productCategory: 'Product Category',
        productName: 'Product Name',
        productPrice: 'Product Price',
        description: 'Product Description',
        cover: 'Cover',
      },
      selector: {
        productAttr: {
          normal: 'Normal',
          coupon: 'Coupon',
          autos: 'Autos',
        },
      },
    },
    audit: {
      menu: {
        title: 'Audit',
      },
      table: {
        title: 'Audits',
      },
    },
    referral: {
      menu: {
        title: 'ReferralPaper',
      },
      table: {
        title: 'ReferralPaper',
      },
    },
    punishment_notification: {
      menu: {
        title: 'PunishmentNotification',
      },
      table: {
        title: 'PunishmentNotification',
      },
    },
    audit_question: {
      menu: {
        title: 'AuditQuestion',
      },
      table: {
        title: 'AuditQuestion',
      },
    },
    reservation: {
      menu: {
        title: 'Reservation',
      },
      table: {
        title: '派工管理',
      },
    },
    system: {
      gateway: {
        methods: {
          get: 'Read',
          post: 'Create',
          put: 'Replace',
          patch: 'Update',
          delete: 'Delete',
        },
        table: {
          api: {
            title: 'API',
            name: 'Name',
            url: 'URL',
            prefix: 'Prefix',
            version: 'Version',
          },
          endpoint: {
            title: 'Endpoint',
            path: 'Path',
            authentication: 'Authentication',
            expire: 'Expire',
            access: 'Access',
            method: 'Method',
            service: 'Service',
          },
        },
      },
    },
  },
};
