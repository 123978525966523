import core from './core';
import bulletin from './bulletin';
import customer from './customer';
import ec from './ec';
import hrms from './hrms';
import cms from './cms';
import contin from './contin';
import report from './report';

const routes = [
  {
    path: '/',
    component: () => import('layouts/NoDrawerLayout.vue'),
    meta: {
      requiresAuth: false,
    },
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import('pages/Login.vue'),
      },
      { path: '', component: () => import('pages/Index.vue') },
    ],
  },
  {
    path: '/private',
    components: {
      default: () => import('layouts/MainLayout.vue'),
    },
    meta: {
      requiresAuth: true,
    },
    props: {
      default: {
        sidebar: true,
      },
    },
    children: [
      ...core,
      ...bulletin,
      ...ec,
      ...customer,
      ...hrms,
      ...cms,
      ...contin,
      ...report,
      {
        path: 'account',
        name: 'Account',
        component: () => import('pages/account/Index.vue'),
      },
      {
        path: '',
        name: 'BoIndex',
        component: () => import('src/pages/BoIndex.vue'),
      },
    ],
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/Error404.vue'),
  },
];

export default routes;
