import { boot } from 'quasar/wrappers';
import * as $moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment($moment);

// "async" is optional;
// more info on params: https://v2.quasar.dev/quasar-cli/boot-files
export default boot(({ app }) => {
  // something to do
  app.config.globalProperties.$moment = moment;
});

export { moment };
