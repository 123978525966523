import Debugger from '../debugger';

const bugger = new Debugger();

const tags = {
  browser: bugger.userAgent,
  url: bugger.location,
};

export default function send(shortMessage, err, level) {
  const data = {
    version: '1',
    host: bugger.location.hostname,
    short_message: shortMessage,
    full_message: err,
    timestamp: Math.floor(Date.now() / 1000),
    level: 1,
    _user_id: 9001,
    _some_info: 'foo',
    _some_env_var: 'bar',
    tags: {
      ...tags,
      level,
    },
  };
  fetch('http://59.126.93.93:12201/gelf', {
    method: 'POST',
    body: JSON.stringify(data),
  });
}
