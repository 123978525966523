import { defineStore } from 'pinia';
import axios from 'axios';
import localforage from 'localforage';
import { authLogin, verifyAccessToken } from 'boot/axiosAPI/auth';
import { getProfileByMemberId } from 'boot/axiosAPI/core';

export const useProfileStore = defineStore('profile', {
  state: () => ({
    profileMap: {},
    myProfile: null,
    isAuth: false,
  }),
  getters: {},
  actions: {
    setMyProfile(profile) {
      this.myProfile = profile;
    },
    async loadProfileByMemberId(memberId) {
      if (this.profileMap[memberId]) {
        return this.profileMap[memberId];
      }
      const profile = await getProfileByMemberId(memberId)
        .then(({ data }) => {
          if (data.returnCode === '000000') {
            return data.info;
          }
          return null;
        });
      this.profileMap = { ...this.profileMap, [memberId]: profile };
      return profile;
    },
    async verifyAndRefreshToken() {
      if (!this.isAuth) {
        const { data: accessToken } = await verifyAccessToken();
        if (accessToken.returnCode === '000000') {
          this.isAuth = true;
          return true;
        } if (accessToken.returnCode === '100038') {
          const token = localStorage.getItem('refreshToken');
          if (token) {
            const postData = {
              grantType: 'refreshToken',
              refreshToken: token,
            };
            const { data: refreshToken } = await axios.post('/api/public/core/v5/auth/token', postData);
            if (refreshToken.returnCode === '000000') {
              const base64Payload = refreshToken.info.accessToken.split('.')[1].replace('-', '+').replace('_', '/');
              const jwtPayload = JSON.parse(window.atob(base64Payload));

              const expiredDate = jwtPayload.exp * 1000;

              localStorage.setItem('token', refreshToken.info.accessToken);
              localStorage.setItem('refreshToken', refreshToken.info.refreshToken);
              localStorage.setItem('memberId', refreshToken.info.memberId);
              localStorage.setItem('expiredDate', expiredDate);
              this.isAuth = true;
              return true;
            }
          }
        }
      }
      this.isAuth = false;
      return false;
    },
    async getPrivilegeByMicroCode(microCode) {
      if (!this.myProfile) {
        const memberId = window.localStorage.getItem('memberId');
        await this.getMyProfile(memberId);
      }
      if (!microCode) return null;

      if (this.myProfile && this.myProfile.member) {
        const memberRoles = this.myProfile.member.roles || [];
        const privileges = new Set();
        memberRoles.forEach((role) => {
          if (role.privileges) {
            JSON.parse(role.privileges).forEach((privilege) => privileges.add(privilege));
          }
        });

        const rule = ['EMPLOYEE', 'MANAGER', 'ADMIN'];
        const roles = Array.from(privileges);
        if (!roles) return null;
        const microRoles = roles.filter((role) => role.indexOf(microCode) > -1).map((role) => rule.indexOf(role.replace(`${microCode}_`, ''))).sort((a, b) => b - a);
        return rule[microRoles[0]] || null;
      }
      return null;
    },
    async getMyProfile(memberId) {
      getProfileByMemberId(memberId)
        .then(({ data }) => {
          if (data.returnCode === '000000') {
            const { idDepartments, idBranches } = data.info;
            window.localStorage.setItem('idDepartments', idDepartments);
            window.localStorage.setItem('idBranches', idBranches);
            localforage.setItem('profile', data.info);
            this.myProfile = data.info;
          }
        });
    },
    logout() {
      this.$reset()
    },
    login(user, router) {
      return new Promise((resolve, reject) => {
        authLogin({
          ...user,
        })
          .then(({ data }) => {
            const $route = router;
            if (data.returnCode === '000000') {
              const {
                forceChangePw, accessToken, refreshToken, memberId, projectCode, status,
                teams, tokenType,
              } = data.info;
              if (forceChangePw) {
                const query = {
                  accessToken,
                  memberId,
                };
                if ($route.query.redirect_url) {
                  query.redirect_url = $route.query.redirect_url;
                }
                resolve({
                  name: 'ForceUpdatePassword',
                  query,
                });
              } else {
                const { redirectUrl } = $route.query.redirect_url || {};
                if (redirectUrl) {
                  const searchParams = new URLSearchParams();
                  searchParams.append('projectCode', projectCode);
                  searchParams.append('accessToken', accessToken);
                  searchParams.append('memberId', memberId);
                  window.location.replace(`${$route.query.redirectUrl}?${searchParams.toString()}`);
                } else {
                  const base64Payload = accessToken.split('.')[1].replace('-', '+').replace('_', '/');
                  const jwtPayload = JSON.parse(window.atob(base64Payload));
                  const expiredDate = jwtPayload.exp * 1000;

                  localStorage.setItem('projectCode', projectCode);
                  localStorage.setItem('token', accessToken);
                  localStorage.setItem('memberId', memberId);
                  localStorage.setItem('status', status);
                  localStorage.setItem('refreshToken', refreshToken);
                  localStorage.setItem('teams', JSON.stringify(teams));
                  localStorage.setItem('tokenType', tokenType);
                  localStorage.setItem('expiredDate', expiredDate);

                  resolve({
                    name: 'BoIndex',
                    query: null,
                  });
                }
              }
            } else {
              resolve();
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
});
