import req from './http';

// User
export const patchPassword = (data) => {
  const url = '/api/backoffice/core/v5/user/password';
  return req.patch(url, data);
};

export const postUser = (data) => {
  const url = '/api/backoffice/core/v5/user/signup';
  return req.post(url, data);
};

export const adminUpdateUserPassword = (memberId, data) => {
  const url = `/api/backoffice/core/v5/user/${memberId}/update-password`;
  return req.patch(url, data);
};

// Profiles
export const putProfileByMemberId = (memberId, data) => {
  const url = `/api/backoffice/core/v5/profiles/${memberId}`;
  return req.put(url, data);
};

export const getProfileByMemberId = (memberId, params) => {
  const url = `/api/backoffice/core/v5/profiles/${memberId}`;
  return req.get(url, params);
};

export const getProfiles = (params) =>
  req.get('/api/backoffice/core/v5/profiles', params);

// Members
export const getMembers = (config) => {
  const url = '/api/backoffice/core/v5/members';
  return req.get(url, config);
}

export const getMemberByMemberId = (memberId, params) => {
  const url = `/api/backoffice/core/v5/members/${memberId}`;
  return req.get(url, params);
};

export const patchMembersSuspend = (data, config) =>
  req.patch(`/api/backoffice/core/v5/user/suspend`, data, config);

export const patchMembersActive = (data, config) =>
  req.patch(`/api/backoffice/core/v5/user/active`, data, config);

// Members Role
export const deleteMembersRole = (id) =>
  req.delete(`/gateway/proxy/core/v1/members/roles/${id}`);

export const postMembersRole = (memberId, data) =>
  req.post(`/gateway/proxy/core/v1/members/${memberId}/roles`, data);

export const getMembersRole = (memberId, params) =>
  req.get(`/gateway/proxy/core/v1/members/${memberId}/roles`, {}, params);

export const getRoles = (params) =>
  req.get('/gateway/proxy/core/v1/roles', {}, params);

export const applyTeams = (memberId, data) =>
  req.post(`/api/backoffice/core/v5/user/apply-teams/${memberId}`, data);

// Departments
export const putDepartments = (idDepartments, data, config) => {
  const url = `/api/backoffice/core/v5/departments/${idDepartments}`;
  return req.put(url, data, config);
};

export const postDepartments = (data) => {
  const url = '/api/backoffice/core/v5/departments';
  return req.post(url, data);
};

export const getDepartments = (config) =>
  req.get('/api/backoffice/core/v5/departments', config);

// Branches
export const putBranches = (idBranches, data, config) => {
  const url = `/api/backoffice/core/v5/branches/${idBranches}`;
  return req.put(url, data, config);
};

export const postBranches = (data) => {
  const url = '/api/backoffice/core/v5/branches';
  return req.post(url, data);
};

export const getBranchesByID = (idBranches, config) => {
  const url = `/api/backoffice/core/v5/branches/${idBranches}`;
  return req.get(url, config);
};

export const getBranches = (config) =>
  req.get('/api/backoffice/core/v5/branches', config);

// Notifications

export const getNotification = (config) =>
  req.get('/api/backoffice/core/v5/notifications', config);

export const postNotification = (data, config) =>
  req.post('/api/backoffice/core/v5/notifications', data, config);
