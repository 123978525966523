const routes = [
  {
    path: 'contin/cs/operation-plan-new',
    name: 'ContinCsOperationPlanNew',
    component: () => import('pages/contin/cs/OperationPlanNew.vue'),
  },
  {
    path: 'contin/cs/operation-plans',
    name: 'ContinCsOperationPlans',
    component: () => import('pages/contin/cs/OperationPlans.vue'),
  },
  {
    path: 'contin/cs/operation-plan-detail',
    name: 'ContinCsOperationPlanDetail',
    component: () => import('pages/contin/cs/OperationPlanDetail.vue'),
  },
  {
    path: 'contin/cs/contact-records',
    name: 'ContinCsContactRecords',
    component: () => import('pages/contin/cs/RecordOfContact.vue'),
  },
  {
    path: 'contin/cs/operation-plans/punch-records',
    name: 'ContinCsOperationPlanPunchRecord',
    component: () => import('pages/contin/cs/OperationPlanPunchRecord.vue'),
  },
];

export default routes;
