const routes = [
  {
    path: 'cms/banner',
    name: 'CmsBanner',
    component: () => import('pages/cms/Banner.vue'),
  },
  {
    path: 'cms/article/list',
    name: 'CmsArticleList',
    component: () => import('pages/cms/articles/ArticleList.vue'),
  },
  {
    path: 'cms/article/edit',
    name: 'CmsArticleEdit',
    component: () => import('pages/cms/articles/EditArticle.vue'),
  },
];

export default routes;
