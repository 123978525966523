export default class Debugger {
  constructor() {
    this.location = Debugger.getLocation();
    this.userAgent = Debugger.getUserAgent();
  }

  static getUserAgent() {
    const { navigator } = window;
    const ua = navigator.userAgent;
    return ua;
  }

  static getLocation() {
    const { location } = window;
    const {
      protocol, hostname, port, pathname, hash,
    } = location;
    const obj = {
      hash,
      hostname,
      pathname,
      port,
      protocol,
    };
    return obj;
  }
}
