import { defineStore } from 'pinia';
import { getBranches } from 'boot/axiosAPI/core';

export const useCoreStore = defineStore('core', {
  state: () => ({
    fileUrl: process.env.FILE_URL || 'https://file.jway.network',
    departments: [],
    branches: [],
  }),
  getters: {},
  actions: {
    clear() {
      this.$reset();
    },
    async loadBranches(params) {
      try {
        const branches = await getBranches({ params });
        if (branches.data.returnCode === '000000') {
          this.branches = branches.data.info.content;
        } else {
          this.branches = [];
        }
        return branches;
      } catch (error) {
        console.error(error);
        return [];
      }
    },
  },
});
