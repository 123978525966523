const routes = [
  {
    path: 'ec/single-category',
    name: 'EcSingleCategory',
    component: () => import('pages/ec/category/Single.vue'),
  },
  {
    path: 'ec/multi-category',
    name: 'EcMultiCategory',
    component: () => import('pages/ec/category/Multi.vue'),
  },
  {
    path: 'ec/single-product',
    name: 'EcSingleProduct',
    component: () => import('pages/ec/product/ProductSingle.vue'),
  },
  {
    path: 'ec/product',
    name: 'EcProduct',
    component: () => import('pages/ec/Product.vue'),
  },
  {
    path: 'ec/order',
    name: 'EcOrder',
    component: () => import('pages/ec/Order.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: 'ec/order-new',
    name: 'EcOrderNew',
    component: () => import('pages/ec/OrderNew.vue'),
  },
  {
    path: 'ec/contract-new',
    name: 'EcContractNew',
    component: () => import('pages/ec/ContractNew.vue'),
  },
  {
    path: 'ec/batch-dispatch',
    name: 'EcBatchDispatch',
    component: () => import('pages/ec/BatchDispatch.vue'),
  }

];

export default routes;
