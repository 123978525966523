import { route } from 'quasar/wrappers';
import {
  createRouter, createMemoryHistory, createWebHistory, createWebHashHistory,
} from 'vue-router';
import { useProfileStore } from 'src/stores/profile';
import routes from './routes';

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */
export default route(({store}/* { store, ssrContext } */) => {
  const profileStore = useProfileStore(store);
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : (process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory);

  const Router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.MODE === 'ssr' ? void 0 : process.env.VUE_ROUTER_BASE),
  });

  Router.beforeEach(async (to, from, next) => {
    const token = window.localStorage.getItem('token');
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (token) {
        const { isAuth, myProfile } = profileStore;
        if (isAuth && myProfile) {
          next();
        } else if (await profileStore.verifyAndRefreshToken()) {
            await profileStore.getMyProfile(window.localStorage.getItem('memberId'));
            next();
          } else {
            next({ name: 'Login' });
          }
      } else {
        next({ name: 'Login' });
      }
    } else {
      next();
    }
  });

  return Router;
});
