import req from './http';

export const authResetPassword = (data) => {
  const url = '/api/public/core/v5/auth/reset-password';
  return req.post(url, data);
};

export const authSendCheckOTP = (data) => {
  const url = '/api/public/core/v5/auth/check-otp';
  return req.post(url, data);
};

export const authSendOTP = (data) => {
  const url = '/api/public/core/v5/auth/send-otp';
  return req.post(url, data);
};

export const authForgotPassword = (data, redirectUrl = null) => {
  let url = '/gateway/public/core/v1/members/forgot-password';
  if (redirectUrl) {
    url += `?redirect_url=${redirectUrl}`;
  }
  return req.post(url, data);
};

export const authLogout = (data) => req.delete(`/gateway/public/core/v1/sessions/${data.token}`);

export const authLogin = (data, config = {}) => req.post('/api/public/core/v5/auth/login', data, config);

export const getSessionByToken = (token) => req.get(`/gateway/public/core/v1/sessions/${token}`);

export const verifyAccessToken = (config = {}) => req.get('/api/backoffice/core/v5/auth/verify/token', config);

export const refreshAccessToken = (data, config = {}) => req.post('/api/public/core/v5/auth/token', data, config);
