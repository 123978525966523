const routes = [
  {
    path: 'customer',
    name: 'Customer',
    component: () => import('pages/Customer.vue'),
  },
  {
    path: 'customer-group',
    name: 'CustomerGroup',
    component: () => import('pages/CustomerGroup.vue'),
  },
  {
    path: 'customer-new',
    name: 'CustomerNew',
    component: () => import('pages/cs/CustomerNew.vue'),
  },
  {
    path: 'cs/license-plate',
    name: 'CsLicensePlate',
    component: () => import('pages/cs/LicensePlates.vue'),
  },
  {
    path: 'cs/dispatches',
    name: 'CsDispatches',
    component: () => import('pages/cs/Dispatches.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: 'cs/contracts',
    name: 'CsContracts',
    component: () => import('pages/cs/ContractsPage.vue'),
  },
  {
    path: 'cs/dispatches-history',
    name: 'CsDispatchesHistory',
    component: () => import('pages/cs/DispatchHistory.vue'),
    meta: {
      keepAlive: true
    }
  },
];

export default routes;
