const routes = [
  {
    path: 'hrms',
    name: 'HrmsIndex',
    component: () => import('pages/hrms/Index.vue'),
  },
  {
    path: 'hrms/punch-record',
    name: 'HrmsPunchRecord',
    component: () => import('pages/hrms/PunchRecord.vue'),
  },
  {
    path: 'hrms/shift-works',
    name: 'HrmsShiftWorks',
    component: () => import('pages/hrms/ShiftWorks.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: 'hrms/loggers',
    name: 'HrmsLoggers',
    component: () => import('pages/hrms/Logger.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: 'hrms/overtime',
    name: 'HrmsOvertime',
    component: () => import('pages/hrms/OverTime.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: 'hrms/overtime-totalsort',
    name: 'HrmsOvertimeTotalSort',
    component: () => import('pages/hrms/OverTimeTotalSort.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: 'hrms/leave-categories',
    component: () => import('pages/hrms/leave-category/IndexPage.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'HrmsLeaveCategories',
        component: () => import('pages/hrms/leave-category/ListPage.vue'),
      },
      {
        path: 'create',
        name: 'HrmsLeaveCategoryCreate',
        component: () => import('pages/hrms/leave-category/CreatePage.vue')
      },
      {
        path: 'edit',
        name: 'HrmsLeaveCategoryEdit',
        component: () => import('pages/hrms/leave-category/EditPage.vue')
      }
    ]
  },
  {
    path: 'hrms/leave-tickets',
    component: () => import('pages/hrms/leave-ticket/IndexPage.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'HrmsLeaveTickets',
        component: () => import('pages/hrms/leave-ticket/ListPage.vue'),
      }
    ]
  }
];

export default routes;
