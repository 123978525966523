import { boot } from 'quasar/wrappers';
import filesize from 'filesize';
import moment from 'moment';
import { useCoreStore } from 'stores/core';

export default boot(({ app, store }) => {
  const coreStore = useCoreStore(store);
  app.config.globalProperties.$utils = {
    normalizeJson(json) {
      if (typeof (json) === 'object') {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in json) {
          if (json[key] === null) {
            delete json[key];
          }
        }
      }
      return JSON.stringify(json);
    },
    fileFormat(file) {
      // name: Original File Name
      // size: File Size
      // type: MINE Type
      const { name, size, type } = file;
      let icon = '';

      switch (type) {
        case 'image/png':
        case 'image/jpeg':
        case 'image/gif':
          icon = 'mdi-file-image-outline';
          break;
        case 'application/pdf':
          icon = 'mdi-file-pdf-outline';
          break;
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        case 'application/msword':
        case 'application/vnd.oasis.opendocument.text':
          icon = 'mdi-file-word-outline';
          break;
        default:
          icon = 'mdi-file';
          break;
      }

      return {
        name,
        size: filesize(size),
        icon,
        type,
        file,
      };
    },
    ROCtoYYYY(rocDate) {
      const year = rocDate.slice(0, 3);
      const month = rocDate.slice(3, 5);
      const day = rocDate.slice(5, 7);
      return `${year * 1 + 1911}-${month}-${day}`;
    },
    ROCtoYYYYMMDD(rocDate) {
      const year = rocDate.slice(0, 3);
      const month = rocDate.slice(3, 5);
      const day = rocDate.slice(5, 7);
      const hour = rocDate.slice(7, 9);
      const min = rocDate.slice(9, 11);
      return `${year * 1 + 1911}-${month}-${day} ${hour}:${min}`;
    },
    YYYYtoROC(yyyyDate) {
      const date = moment(yyyyDate);
      return {
        year: date.year(),
        month: date.month() + 1,
        day: date.date(),
        hour: date.hours(),
        min: date.minutes(),
      };
    },
    transformImage(image) {
      const { fileUrl } = coreStore;
      let url = '';
      if (image === null) {
        url = `${fileUrl}/null`;
      } else {
        url = image.includes('http') ? image : `${fileUrl}/${image || 'null'}`;
      }
      return url;
    },
  };
});
