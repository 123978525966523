import { boot } from 'quasar/wrappers';
import Dialog from 'quasar/src/plugins/Dialog.js';
import graylog from 'lib/graylog';

export default boot(({ app }) => {
  app.config.errorHandler = (err, vm, info) => {
    graylog(`Vue ${info.toString()}`, err.toString(), 'error');
    Dialog.create({
      title: 'core-錯誤',
      message: `Erro: ${err}<br /> Info: ${info} <br /> 出現該訊息麻煩「複製」或是「截圖」並聯繫工程師。`,
      html: true,
    });
  };
});
